// @flow

import type { Layout } from 'config/constants';
import type { VirtualMonitorSplit } from 'generated/graphql';

import {
  dreOrientationMarkerWidget,
  Corners,
} from '../ViewportDre/modules/dreOrientationCubeWidget';
import vtkOrientationMarkerWidget from '@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget';

import vtkAnnotatedCubeActor from '@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor';
import { VirtualMonitorSplitValues } from 'generated/graphql';
import type { IView } from 'react-vtk-js';
import type { Stack } from '../ViewportsConfigurations/types';

import {
  BasicTextSRStorage,
  ComprehensiveSRStorage,
  EncapsulatedPDFStorage,
  EnhancedSRStorage,
  XRayRadiationDoseSRStorage,
} from './SOPClasses';
import {
  allPixels,
  isBetaSupportedRender,
  isSupportedRender,
  supportedNonPixelsSOPClass,
} from './stackUtils';

export const PDF_SOP_CLASS_UIDS = [EncapsulatedPDFStorage];

export const SR_SOP_CLASS_UIDS = [
  BasicTextSRStorage,
  ComprehensiveSRStorage,
  EnhancedSRStorage,
  XRayRadiationDoseSRStorage,
];

export const SUPPORTED_NON_PIXEL_SOP_CLASS_UIDS: string[] = [
  ...PDF_SOP_CLASS_UIDS,
  ...SR_SOP_CLASS_UIDS,
];

export function generateViewportId(
  windowId: number | string,
  row: number | string,
  col: number | string
): string {
  return `Viewport-${windowId}-${row}-${col}`;
}

export function viewportIdToConfig(viewportId: string): {
  windowId: string,
  rowIndex: number,
  colIndex: number,
} {
  const [windowId, rowIndex, colIndex] = viewportId.split('-').slice(1);
  return {
    windowId: windowId,
    rowIndex: Number(rowIndex),
    colIndex: Number(colIndex),
  };
}

export function isInViewportExpandableVerticalArea(
  cols: number,
  viewportId: string,
  col: number
): boolean {
  const { colIndex } = viewportIdToConfig(viewportId);
  const isLeft = col < Math.floor(cols / 2);
  const isRight = col >= Math.floor(cols / 2);

  if (isLeft && colIndex < Math.floor(cols / 2)) {
    return true;
  }
  if (isRight && colIndex >= Math.floor(cols / 2)) {
    return true;
  }
  return false;
}

export function isInViewportExpandableHorizontalArea(
  rows: number,
  viewportId: string,
  row: number
): boolean {
  const { rowIndex } = viewportIdToConfig(viewportId);
  const isTop = row < Math.floor(rows / 2);
  const isBottom = row >= Math.floor(rows / 2);

  if (isTop && rowIndex < Math.floor(rows / 2)) {
    return true;
  }
  if (isBottom && rowIndex >= Math.floor(rows / 2)) {
    return true;
  }
  return false;
}

export function getViewportGrid(
  viewportUpIds: $ReadOnlyArray<string>,
  viewportIdGenerator: (row: string, col: string) => string,
  layout: Layout,
  virtualMonitorSplit: ?VirtualMonitorSplit,
  minColumnsToEnableMultipleExpansionAreas: number
): string[][] {
  const hasExpandedViewports = viewportUpIds.length > 0;

  const [numCols, numRows] =
    virtualMonitorSplit == null && hasExpandedViewports ? [1, 1] : [layout[1] + 1, layout[0] + 1];

  const multipleExpansionAreasMode =
    virtualMonitorSplit !== VirtualMonitorSplitValues.Horizontal &&
    numCols >= minColumnsToEnableMultipleExpansionAreas &&
    (numRows > 1 || numCols > 2);

  const rows = Array.from({ length: layout[0] ?? 0 }, (_, index) => String(index));
  const cols = Array.from({ length: layout[1] ?? 0 }, (_, index) => String(index));

  const shouldUseFullscreen =
    (cols.length === 1 && rows.length === 2) || (cols.length === 2 && rows.length === 1);

  const isHorizontalSplit = virtualMonitorSplit === VirtualMonitorSplitValues.Horizontal;

  const getRowContent = (row: string[], index: number, arr: Array<string[]>): string[] => {
    // Replace all cells in a row with the expanded viewport
    if (isHorizontalSplit) {
      let expandedColumn = row.find((col) => viewportUpIds.includes(col));

      if (expandedColumn == null && index === 0 && arr.length === 2 && shouldUseFullscreen) {
        expandedColumn = arr[index + 1]?.find((col) => viewportUpIds.includes(col));
      }

      if (expandedColumn == null && (index === 2 || (index === 1 && shouldUseFullscreen))) {
        expandedColumn = arr[index - 1]?.find((col) => viewportUpIds.includes(col));
      }

      if (expandedColumn != null) {
        // $FlowIgnore[incompatible-return] - already checked for null
        return row.map(() => expandedColumn);
      }

      return row;
    }

    if (hasExpandedViewports && !isHorizontalSplit) {
      return row.map((viewportId: string, colIndex: number) => {
        return (
          (multipleExpansionAreasMode ||
          virtualMonitorSplit === VirtualMonitorSplitValues.Horizontal
            ? viewportUpIds.find((viewportUpId) =>
                isInViewportExpandableVerticalArea(layout[1], viewportUpId, colIndex)
              )
            : viewportUpIds[0]) ?? viewportId
        );
      });
    }
    return row;
  };

  const grid = rows.map((row) => cols.map((col) => viewportIdGenerator(row, col)));

  return grid.map((row, index, arr) => getRowContent(row, index, arr));
}

function insertAtConditionally(array: Array<string>, index: number, item: null | string) {
  if (item == null) return array;

  return [...array.slice(0, index), item, ...array.slice(index)];
}

export const GAP = 3;

type GetViewportsGridWithGapsArgs = {
  currLayout: ?Layout,
  virtualMonitorSplit: ?VirtualMonitorSplit,
  minColumnsToEnableMultipleExpansionAreas: number,
  viewportUpIds: $ReadOnlyArray<string>,
  windowId: string,
};

export function getViewportsFlexGridOptions({
  currLayout,
  virtualMonitorSplit,
  minColumnsToEnableMultipleExpansionAreas,
  viewportUpIds,
  windowId,
}: GetViewportsGridWithGapsArgs): {
  gridTemplateAreas: string,
  gridTemplateColumns: Array<string>,
  gridTemplateRows: Array<string>,
} {
  const rows = Array.from({ length: currLayout?.[0] ?? 0 }, (_, index) => String(index));
  const cols = Array.from({ length: currLayout?.[1] ?? 0 }, (_, index) => String(index));
  const layout = [rows.length - 1, cols.length - 1];

  const hasExpandedViewports = viewportUpIds.length > 0;
  const [numCols, numRows] =
    virtualMonitorSplit == null && hasExpandedViewports ? [1, 1] : [layout[1] + 1, layout[0] + 1];

  // The following flag is used to determine whether we should render the separator either horizontally or vertically.
  // We always want to render it even when the viewport is expanded to ensure the grid is not mutated.
  const multipleExpansionAreasMode =
    virtualMonitorSplit != null &&
    numCols >= minColumnsToEnableMultipleExpansionAreas &&
    (numRows > 1 || numCols > 2);

  // We first generate a viewport grid with a conditional vertical separator.
  const baseViewportGrid = getViewportGrid(
    viewportUpIds,
    (row, col) => generateViewportId(windowId, row, col),
    currLayout ?? [0, 0],
    virtualMonitorSplit,
    minColumnsToEnableMultipleExpansionAreas
  ).map((rowContent) => {
    return `"${insertAtConditionally(
      rowContent,
      Math.ceil(layout[1] / 2),
      multipleExpansionAreasMode && virtualMonitorSplit === VirtualMonitorSplitValues.Vertical
        ? 'separator'
        : null
    ).join(' ')}"`;
  });

  // Then we insert a horizontal separator if needed.
  const gridTemplateAreas = insertAtConditionally(
    baseViewportGrid,
    Math.ceil(layout[0] / 2),
    multipleExpansionAreasMode && virtualMonitorSplit === VirtualMonitorSplitValues.Horizontal
      ? `"${Array.from({ length: layout[1] + 1 })
          .map(() => 'separator')
          .join(' ')}"`
      : null
  ).join(' ');

  const hasOddColumns = cols.length % 2 !== 0;
  const columnSizes = [
    insertAtConditionally(
      cols.reduce((acc, col, index, arr) => {
        const totalColumns = arr.length;
        const separatorIndex = Math.floor(totalColumns / 2);
        const columnsPerSection = separatorIndex + 1;

        if (
          hasExpandedViewports &&
          hasOddColumns &&
          virtualMonitorSplit === VirtualMonitorSplitValues.Vertical
        ) {
          const isExpanded = viewportUpIds.some((viewportUpId) =>
            isInViewportExpandableVerticalArea(layout[1], viewportUpId, index)
          );

          if (index < separatorIndex) {
            const previousIsExpanded = acc.length > 0 && acc.some((size) => parseInt(size) > 1);
            if (previousIsExpanded) {
              return [...acc, '0fr'];
            }

            if (isExpanded) {
              return [`${columnsPerSection}fr`];
            }
          }

          if (index > separatorIndex) {
            if (isExpanded) {
              const leftSideIsExpanded = acc
                .slice(0, separatorIndex)
                .some((size) => parseInt(size) > 1);
              // With odd columns, the right side will have one more column than the left side, except when the left side is expanded.
              const expansionValue = leftSideIsExpanded ? columnsPerSection : columnsPerSection - 1;
              return [
                ...acc.map((s, i) => (i >= separatorIndex ? '0fr' : s)),
                `${expansionValue}fr`,
              ];
            }
          }
        }
        return [...acc, '1fr'];
      }, []),
      Math.ceil(layout[1] / 2),
      // We add a gap between the columns only when the viewports are split vertically.
      multipleExpansionAreasMode && virtualMonitorSplit === VirtualMonitorSplitValues.Vertical
        ? `${GAP}px`
        : null
    ).join(' '),
  ];

  const hasOddRows = rows.length % 2 !== 0;
  const rowSizes = [
    insertAtConditionally(
      rows.reduce((acc, row, index, arr) => {
        const totalRows = arr.length;
        const separatorIndex = Math.floor(totalRows / 2);
        const rowsPerSection = separatorIndex + 1;

        if (
          hasExpandedViewports &&
          hasOddRows &&
          virtualMonitorSplit === VirtualMonitorSplitValues.Horizontal
        ) {
          const isExpanded = viewportUpIds.some((viewportUpId) =>
            isInViewportExpandableHorizontalArea(layout[0], viewportUpId, index)
          );

          if (index < separatorIndex) {
            const previousIsExpanded = acc.length > 0 && acc.some((size) => parseInt(size) > 1);
            if (previousIsExpanded) {
              return [...acc, '0fr'];
            }

            if (isExpanded) {
              return [`${rowsPerSection}fr`];
            }
          }

          if (index > separatorIndex) {
            if (isExpanded) {
              const leftSideIsExpanded = acc
                .slice(0, separatorIndex)
                .some((size) => parseInt(size) > 1);
              // With odd columns, the right side will have one more column than the left side, except when the left side is expanded.
              const expansionValue = leftSideIsExpanded ? rowsPerSection : rowsPerSection - 1;
              return [
                ...acc.map((s, i) => (i >= separatorIndex ? '0fr' : s)),
                `${expansionValue}fr`,
              ];
            }
          }
        }
        return [...acc, '1fr'];
      }, []),
      Math.ceil(layout[0] / 2),
      // We add a gap between the rows only when the viewports are split horizontally.
      multipleExpansionAreasMode && virtualMonitorSplit === VirtualMonitorSplitValues.Horizontal
        ? `${GAP}px`
        : null
    ).join(' '),
  ];

  return {
    gridTemplateAreas,
    gridTemplateColumns: columnSizes,
    gridTemplateRows: rowSizes,
  };
}

export function canRenderStack(stack: ?Stack): boolean {
  return isStackOfficiallySupported(stack) || (stack != null && allPixels(stack));
}

export function isStackOfficiallySupported(stack: ?Stack): boolean {
  return stack != null && (isSupportedRender(stack, 'vtk') || supportedNonPixelsSOPClass(stack));
}

export function isStackBetaSupported(stack: ?Stack): boolean {
  return stack != null && isBetaSupportedRender(stack, 'vtk');
}

export function frameSmidToInstanceSmid(frameSmid: string): string {
  return frameSmid.split(':')[0];
}

export function shouldInvertImaging(
  userHasInverted: boolean,
  imagingIsInvered: boolean,
  featureFlagEnabled: boolean
): boolean {
  // if the feature flag is disabled, we should use the user's setting
  if (!featureFlagEnabled) {
    return userHasInverted;
  }
  // if the feature flag is enabled, we should detect if the image has been "pre inverted" on mmodality
  // user has inverted : image is inverted on mmodality : should we use an inverted color map
  // true : true : false
  // true : false : true
  // false : true : true
  // false : false : false
  return userHasInverted !== imagingIsInvered;
}

export const createOrientationCubeInstance = (view: ?IView): ?typeof vtkOrientationMarkerWidget => {
  const axes = vtkAnnotatedCubeActor.newInstance();
  const renderer = view?.getRenderer()?.get();
  const interactor = view?.getRenderWindow()?.get()?.getInteractor();

  if (renderer == null || interactor == null) return null;
  axes.setDefaultStyle({
    text: 'L',
    fontStyle: 'bold',
    fontFamily: 'Roboto',
    faceRotation: 180,
    fontColor: '#BA68C8',
    fontSizeScale: (res) => res * 0.6,
    faceColor: '#E0E0E0',
    edgeThickness: 0.08,
    edgeColor: '#B0B0B0',
    resolution: 2000,
  });
  axes.setXMinusFaceProperty({
    text: 'R',
    fontColor: '#FFB74D',
    faceRotation: 180,
  });
  axes.setYPlusFaceProperty({
    text: 'P',
    fontColor: '#64B5F6',
    faceRotation: 180,
  });
  axes.setYMinusFaceProperty({
    text: 'A',
    fontColor: '#E57373',
    faceRotation: 0,
  });
  axes.setZPlusFaceProperty({
    text: 'I',
    fontColor: '#81C784',
    faceRotation: 180,
  });
  axes.setZMinusFaceProperty({
    text: 'S',
    fontColor: '#FFF176',
    faceRotation: 180,
  });

  const orientationWidget = dreOrientationMarkerWidget.newInstance({
    actor: axes,
    interactor: view?.getRenderWindow()?.get()?.getInteractor(),
    parentRenderer: renderer,
  });
  orientationWidget.setViewportCorner(Corners.TOP_RIGHT);
  orientationWidget.setMaxPixelSize(125);
  orientationWidget.setEnabled(true);

  return orientationWidget;
};
