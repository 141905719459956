// @flow

import type { FrameDataFragment, ImageParams } from 'generated/graphql';
import type { FullSingleLayerStack } from '../../../ViewportsConfigurations/types';
import type { $AxisDirection } from '@kitware/vtk.js';

import { calculateAxis } from '../../utils/math';
import { BaseImagingProvider } from './BaseImagingProvider';
import type { BaseImagingProviderArgs } from './BaseImagingProvider';

export class BaseSingleStackImagingProvider<
  T: FullSingleLayerStack = FullSingleLayerStack,
> extends BaseImagingProvider<T> {
  #frameAxes: Set<$AxisDirection>;
  #validDirection: boolean;
  #frameSmidsMap: { [key: number]: string };

  constructor(providerArgs: BaseImagingProviderArgs<T>) {
    super(providerArgs);

    // Calculate these once for the getters to reference
    this.#frameAxes = new Set(
      this.stack.frames.map((frame) => {
        return calculateAxis(frame.direction.data);
      })
    );

    this.#validDirection = this.stack.frames.every((frame) => frame.direction.validDirection);
    this.#frameSmidsMap = Object.fromEntries(
      this.stack.frames.map((frame, index) => [index, frame.smid])
    );
  }

  hasValidDirection(): boolean {
    return this.#validDirection;
  }

  /* Map of the frame order in the series to the frame SMID */
  // $FlowIgnore[unsafe-getters-setters]
  get frameSmidsMap(): { [key: number]: string } {
    return this.#frameSmidsMap;
  }
  /* Number of slices / frames in the series */
  // $FlowIgnore[unsafe-getters-setters]
  get stackSize(): number {
    return this.stack.frames.length;
  }

  /* DICOM instance tags for this stack */
  // $FlowIgnore[unsafe-getters-setters]
  get frameTags(): $ReadOnlyArray<FrameDataFragment> {
    return this.stack.frames;
  }

  /**
   * General parameters for the image used in calculating transforms, positioning, etc.
   */
  // $FlowIgnore[unsafe-getters-setters]
  get imageParams(): ImageParams {
    return this.stack.imageParams;
  }

  getFrameAxes(): Set<$AxisDirection> {
    return this.#frameAxes;
  }

  hasPixels(): boolean {
    return this.stack.frames[0].hasPixels;
  }

  canRender(renderEngine: string): boolean {
    return this.stack.supportedRenderers[renderEngine] ?? false;
  }

  is3Dable(): boolean {
    return this.stack.is3Dable;
  }

  estimateMemory(): number {
    const perChannelBytes = this.supportedTextures.norm16 ? 2 : 4;
    const totalByteSize = this.stack.frames.reduce(
      (total, frame) =>
        total + (frame.colorChannels ?? 1) * perChannelBytes * frame.size[0] * frame.size[1],
      0
    );

    return totalByteSize;
  }

  estimatePerFrameMemory(): number {
    const frameSize = this.stack.frames.length;
    return this.estimateMemory() / frameSize;
  }

  seriesAreHomogenous(): boolean {
    return (
      this instanceof BaseSingleStackImagingProvider &&
      this.stack.frames.every((frame) => frame.series.smid === this.stack.frames[0].series.smid)
    );
  }

  getNumberOfColorChannels(frameIndex: number): number {
    const frame = this.stack.frames[frameIndex];
    const firstFrame = this.stack.frames[0];
    return frame?.colorChannels ?? firstFrame?.colorChannels ?? 1;
  }
}
